import LoginRequest from '@/request/System/LoginRequest'
import { errorTip, successTip } from '@/utils/Tip'

const loginRequest = new LoginRequest()
export default {
  data () {
    return {
      form: {
        /* user_name: 'admin',
        password: '111111' */
        UserName: '',
        Password: ''
      },
      rules: {
        UserName: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入用户名'
          }
        ],
        Password: [{
          required: true,
          trigger: 'blur',
          message: '请输入密码'
        }]
      }
    }
  },
  // 初始化函数
  methods: {
    // 登录点击
    LoginClick: async function () {
      // 登录操作
      const result = await loginRequest.LoginPost(this.form)
      if (result.code !== 200) errorTip(this, result.message)

      sessionStorage.setItem('token', result.data.token)
      sessionStorage.setItem('menu', JSON.stringify(result.data.menu))
      successTip(this, '登录成功')
      // 跳转页面
      setTimeout(() => {
        this.$router.push('/SystemHome')
      }, 2000)
    }
  }
}
